header {
  background-color: #212121;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3em;
}

header nav a {
  margin: 0 1em;
}

input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding: 0.5em 0.2em;
  width: auto;
  transition: all 0.2s;
  font-size: 1.3rem;
  color: #eee;
}

input:active,
input:focus {
  outline: none;

  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
}

input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.2s;
}

input:active::placeholder,
input:focus::placeholder {
  color: rgba(255, 255, 255, 0.2);
}

main {
  padding: 2em 0;
}

.about {
  padding: 2em;
  text-align: center;
  width: 60%;
  font-size: x-large;
  font-weight: bold;
}

form {
  width: 100%;
  display: flex;
  justify-content: center;
}

input {
  width: 80%;
  margin: 0 1em;
}

button {
  padding: 0.2em 1em;
  border: 0;
  border-radius: 4px;
  font-size: 1.1rem;
  cursor: pointer;
}

.card {
  max-width: 300px;
  max-height: 380px;
  border-radius: 4px;
  background-color: #1b1b1b;
  cursor: pointer;
  transition: all 0.2s;
}

.card:hover {
  opacity: 0.8;
}

.card .img-wrapper {
  width: auto;
}

.card img {
  width: 100%;
  height: 200px;
}

.card .info-wrapper {
  padding: 1em;
}
.container-grid {
  padding: 2em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 1em;
  justify-content: center;
}
.genres {
  width: 100%;
}
.genres, .search, h2, h1 {
  text-align: center;
}

.game-content .image-container {
  width: 95vw;
  margin: auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1em 0;
}

.image-container div {
  margin: auto;
  width: 90%;
}

.image-container img {
  margin: auto;
  text-align: center;
  width: 100%;
}

.details {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.space {
  justify-content: space-between;
}

.game-details {
  width: 90%;
}

.detail {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  text-align: center;
}